import { Location } from "./location";

const headers = [
  { label: "name", key: "name" },
  { label: "google_location", key: "google_location.canonical_name" },
  { label: "reach", key: "google_location.reach" },
  { label: "type", key: "type" },
  { label: "region", key: "nuts_region" },
  { label: "county", key: "county" },
  { label: "country", key: "country" },
  { label: "population_region", key: "population.region" },
  { label: "region_area_in_ha", key: "population.region_area_in_ha" },
  { label: "residents_per_ha", key: "population.residents_per_ha" },
];

function transform( data ) {
  const newData = [];
  data.forEach( ( location ) => {
    const entry = {};
    headers.forEach( ( header ) => {
      try {
        if ( header.key.indexOf( "." ) > 0 ) {
          let temp = location;
          header.key.split( "." ).forEach( ( subKey ) => temp = temp[ subKey ] );
          entry[ header.label ] = temp;
        } else {
          entry[ header.label ] = location[ header.key ];
        }
      } catch ( error ) {
        entry[ header.label ] = null;
      }
    } );
    newData.push( entry );
  } );
  return newData;
}

const toExport = {
  headers,
  transform,
};

export default toExport;
