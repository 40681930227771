import Ssomg from "ssomg-react";
import { AxiosResponse, AxiosPromise } from "axios";
import { Location } from "../models/location";

const LocationAPI = new Ssomg.Network( "https://locations.roardigital.co.uk/api/locations", "5e32f72ec3392b00118027f7" );

function getLocsFromPolyline( polyline:string ):Promise<Location[]> {
  return LocationAPI.get( `/within/?polyline=${ polyline }` )
    .then( ( response: AxiosResponse ): Location[] => {
      if ( response.status >= 400 ) {
        throw new Error( `Response of: ${ response.status } from server` );
      } else {
        return response.data.data;
      }
    } )
}

function getLocsFromPoint( lat: number, lng: number, radius: number ):Promise<Location[]> {
  return LocationAPI.get( `/near?radius=${ radius }&lat=${ lat }&lng=${ lng }` )
    .then( ( response: AxiosResponse ): Location[] => {
      if ( response.status >= 400 ) {
        throw new Error( `Response of: ${ response.status } from server` );
      } else {
        return response.data.data;
      }
    } )
}

export default {
  getLocsFromPolyline,
  getLocsFromPoint,
};