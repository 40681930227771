import React, { useState, useEffect } from "react";
import { encode } from "geojson-polyline";

import MapView from "./MapView";
import "./map.scss";
import { circle, polygon } from "leaflet";
import LocationAPI from "../../common/LocationAPI.ts";

const getShapeType = function ( layer ) {
  console.log( layer );
  if ( layer._radius && layer._latlng ) {
    return "circle";
  }

  if ( layer._latlngs ) {
    return "polygon";
  }
};
const booted = false;

function MapPage( props ) {
  const [ layers, setLayers ] = useState( [] );
  const [ locations, setLocations ] = useState( [] );
  const [ mapContext, setmapContext ] = useState( {} );
  const [ mapUpdateFlag, setmapUpdateFlag ] = useState( false );
  const [ changeCount, setChangeCount ] = useState( 0 );

  const Circle = circle( [ 52.82932091031373, -3.5595703125 ], 5000 );

  function setContext( ctx ) {
    setmapContext( ctx );
    setmapUpdateFlag( !mapUpdateFlag );
  }

  function addPointMarker( lat, lng, radius ) {
    if ( mapContext && mapContext.layerContainer ) {
      mapContext.layerContainer.addLayer( circle( [ lat, lng ], radius ) );
    }
    setmapContext( mapContext );
    setmapUpdateFlag( !mapUpdateFlag );
  }
  function addPolygonMarker( coordinates ) {
    if ( mapContext && mapContext.layerContainer ) {
      mapContext.layerContainer.addLayer( polygon( coordinates ) );
    }
    setmapContext( mapContext );
    setmapUpdateFlag( !mapUpdateFlag );
  }

  useEffect( () => {
    console.log( locations );
    // handleDraw();
  }, [ locations ] );

  useEffect( () => {
    console.log( mapContext );
    if ( mapContext && mapContext.layerContainer ) {
      setLayers( mapContext.layerContainer.getLayers() );
    }
  }, [ mapUpdateFlag ] );

  function handlePolygonDraw( layer ) {
    const geoJsonOutput = layer.toGeoJSON();
    const encoded = encode( { type: "Polygon", coordinates: geoJsonOutput.geometry.coordinates } );
    return LocationAPI.getLocsFromPolyline( encoded.coordinates[ 0 ] )
      .catch( ( err ) => console.log( err ) );
  }

  function handleDraw() {
    const apiPromises = [];
    layers.forEach( ( layer ) => {
      const type = getShapeType( layer );
      if ( type === "polygon" ) {
        apiPromises.push( handlePolygonDraw( layer ) );
      } else if ( type === "circle" ) {
        apiPromises.push( handleCircleDraw( layer ) );
      }
    } );
    const newLocs = [];
    Promise.all( apiPromises )
      .then( ( resolutions ) => {
        resolutions.forEach( ( resolvedPromise ) => {
          if ( Array.isArray( resolvedPromise ) ) {
            newLocs.push( ...resolvedPromise );
          }
        } );
        setLocations( newLocs );
      } )
      .catch( console.log );
  }

  function handleCircleDraw( layer ) {
    const { lat, lng } = layer.getLatLng();
    const radius = Math.floor( layer.getRadius() );

    console.log( radius, lat, lng );
    return LocationAPI.getLocsFromPoint( lat, lng, radius )
      .catch( ( err ) => console.log( err ) );
  }

  return (
    <MapView
      layers={layers}
      setContext={setContext}
      changeCount={changeCount}
      setChangeCount={setChangeCount}
      handleDraw={handleDraw}
      locations={locations}
      {...props}
    />
  );
}

export default MapPage;
