/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";

import {
  BrowserRouter as Router, Route, Link, BrowserRouter, Switch,
} from "react-router-dom";

import ReactGA from "react-ga";
import Ssomg from "ssomg-react";
import MapPage from "./pages/map/Map";
import Nav from "./components/nav/Nav";
import Home from "./pages/home/Home";

const LocationAPI = new Ssomg.Network( "https://locations.roardigital.co.uk/api/locations", "5e32f72ec3392b00118027f7" );

class App extends Component {
  constructor( props ) {
    super( props );
    Ssomg.onload();
    if ( process.env.NODE_ENV === "production" ) {
      // ReactGA.initialize( "my google tracking id", { titleCase: false } );
    }
    ReactGA.ga( "set", "checkProtocolTask", null );
    this.state = {
      user: undefined,
      booted: false,
      error: undefined,
    };
  }

  componentDidMount() {
    this.loadAllData();
  }

  loadAllData( isTokenRefreshCallback = false ) {
    const { booted } = this.state;
    if ( booted && isTokenRefreshCallback ) {
      return Ssomg.getObject().then( ( user ) => {
        this.setState( { user } );
      } );
    }
    return Ssomg.getObject()
      .then( ( user ) => {
        this.setState( { error: undefined, user, booted: true } );
      } )
      .catch( ( err ) => {
        console.log( JSON.stringify( err ) );
        let errMessage;
        if ( err.response && err.response.data && err.response.data.error ) {
          errMessage = err.response.data.error;
        } else if ( err.message ) {
          errMessage = err.message;
        } else if ( typeof err !== "string" ) {
          if ( isTokenRefreshCallback ) {
            errMessage = "An authentication error occurred. Please clear your cookies or reload the browser.";
          } // else - otherwise authentication still in progress to keep loading spinner
        } else {
          errMessage = err;
        }

        if ( errMessage ) {
          this.setState( { error: errMessage } );
        }
      } );
  }

  render() {
    const { error, booted, user } = this.state;
    return (
      <Router className="App">
        <header className="header">
          <div className="header__inner">
            <div className="header__left">
              <h4>Scraper</h4>
            </div>
            <div className="header__right">
              <img className="roar-branding" alt="Roar Digital" src="/roar-logo-dark.png" />
              <Link to="/auth/logout" className="logout">
                <i className="fas fa-sign-out-alt" />
              </Link>
            </div>
          </div>
        </header>
        <div className="app" key={( user && user._id ) || "nouserset"}>
          <Nav user={user} updateNav={() => this.loadAllData()} />
          <main>
            <Route
              exact
              path="/auth/:action"
              render={( props ) => <Ssomg.component {...props} callback={() => { this.loadAllData( true ); }} />}
            />
            { user ? (
              <Switch>
                <Route exact path="/map">
                  <MapPage API={LocationAPI} />
                </Route>
                <Route exact path="/" component={Home} />
              </Switch>
            ) : <h2>Verifying User...</h2> }
          </main>
        </div>
      </Router>
    );
  }
}

export default App;
