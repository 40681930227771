import React, { useEffect, useState } from "react";
import {
  Map, TileLayer, FeatureGroup, useLeaflet,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import CustomMap from "./customMap";

function EditableMap( props ) {
  const {
    setContext, changeCount, setChangeCount,
  } = props;

  function change( e ) {
    console.log( "CHANGED", changeCount, e );
    setChangeCount( Date.now() );
  }
  return (
    // @todo render locations
    <div style={{ height: "600px", width: "600px" }}>
      <Map center={[ 53.3781, -3.4360 ]} zoom={6} zoomControl={false}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
        <FeatureGroup>
          <CustomMap key={`testing-${ changeCount }`} change={changeCount} setContext={setContext} />
          <EditControl
            onEdited={( e ) => change( e )}
            onCreated={( e ) => change( e )}
            onDeleted={( e ) => change( e )}
            position="topright"
            edit={{ remove: true }}
            draw={{
              polyline: false,
              polygon: {
                allowIntersection: false, // Restricts shapes to simple polygons
                drawError: {
                  color: "#e1e100", // Color the shape will turn when intersects
                  message: "<strong>Error -<strong> No intersections permitted.", // Message that will show when intersect
                },
              },
              marker: false,
              circlemarker: false,
              rectangle: false,
            }}
          />
        </FeatureGroup>
      </Map>
    </div>
  );
}

export default EditableMap;
