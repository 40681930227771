import React, { useEffect } from "react";
import {
  Map, TileLayer, FeatureGroup, useLeaflet,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";

function CustomMap( { change, setContext } ) {
  console.log( "CUSTOM MAP BABY" );
  const lContext = useLeaflet();
  useEffect( () => {
    console.log( lContext );
    setContext( lContext );
  }, [ change ] );

  return <>{change}</>;
}

CustomMap.propTypes = {

};

export default CustomMap;
