import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./Sidebar.scss";

type sidebarProps = {
  children: React.ReactNode;
  open: boolean;
  style: object;
  to: string;
  back: ( ( event: React.MouseEvent<HTMLElement> ) => void )| string;
}

export default function Sidebar( props: sidebarProps ) {
  const {
    open, children, style, to, back,
  } = props;
  const status = open ? "open" : "closed";
  let bgmarkup;
  if ( to ) {
    bgmarkup = <Link to={to} data-testid="sidebarbg-to" className={`sidebar__bg sidebar__bg--${ status }`} />;
  } else if ( back && typeof back === "string" ) {
    const pathArr = back.split( "/" );
    pathArr.pop();
    bgmarkup = <Link to={pathArr.join( "/" )} data-testid="sidebarbg-backpath" className={`sidebar__bg sidebar__bg--${ status }`} />;
  } else if ( back && typeof back === "function" ) {
    bgmarkup = <div onClick={back} aria-label="back" data-testid="sidebarbg-back" className={`sidebar__bg sidebar__bg--${ status }`} />;
  } else {
    bgmarkup = <div className={`sidebar__bg sidebar__bg--${ status }`} data-testid="sidebarbg-none" />;
  }
  return (
    <>
      <div className={`sidebar sidebar--${ status }`} style={style} data-testid="sidebar">
        {children}
      </div>
      {bgmarkup}
    </>
  );
}

Sidebar.defaultProps = {
  children: undefined,
  style: {},
  to: undefined,
  back: undefined,
};

Sidebar.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  to: PropTypes.string,
  back: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.func,
  ] ),
};
