import React from "react";
import PropTypes from "prop-types";

const Home = () => (
  <div>
      Home
  </div>
);

Home.propTypes = {

};

export default Home;
