import React, { useState } from "react";
import PropTypes from "prop-types";
import { CSVLink, CSVDownload } from "react-csv";

function Merge( { locations, keywordString, replace } ) {
  const data = [];
  keywordString.split( "," ).forEach( ( keyword ) => {
    locations.forEach( ( location ) => {
      let theKeyword = keyword.trim();
      if ( replace && replace.length > 0 && keyword.indexOf( replace ) >= 0 ) {
        theKeyword = keyword.split( replace ).join( location.name );
      }
      data.push( {
        ...location,
        keyword: theKeyword,
      } );
    } );
  } );
  console.log( data );
  return (
    <CSVLink data={data} className="primary" filename="merged_location_export.csv">Download Merged</CSVLink>
  );
}

Merge.propTypes = {

};

export default Merge;
