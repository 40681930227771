/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import currentUser from "../../models/currentUser";
import ReactGA from "react-ga";

type NavLinks = {
  error: string | null,
  data: NavLink[],
}
type NavLink = {
  name: string,
  path: string,
}

function Nav( props: {location: {pathname: string }, updateNav: Function, user: currentUser } ) {
  const {
    location,
    updateNav,
    user,
  } = props;

  useEffect( () => {
    ReactGA.pageview( location.pathname );
  }, [ location ] );

  const links: NavLinks = {
    error: null,
    data: [
      {
        path: "/",
        name: "Dashboard"
      },
      {
        path: "/map",
        name: "Locations"
      }
    ]
  }

  // normalise path
  let path = location.pathname;
  if ( path.charAt( path.length - 1 ) === "/" && path.length > 1 ) {
    path = path.slice(0, path.length - 1 );
  }

  return (
    <nav className="nav max-width">
      <div className="nav__links">
        {createLinks( links, path )}
        <div className="text-right">
          {/* {refreshButton( accounts, updateNav )} */}
          {newLink( user ) }
        </div>
      </div>
    </nav>
  );
}

function newLink( user: currentUser ) {
  if ( user && user.roles && user.roles.indexOf("admin") >= 0 ){
    return (
      <Link to="/new" data-testid="nav-create" className="nav__link__button inline-block">
        <div className="nav__link" >
          <i className="fas fa-plus" />
        </div>
      </Link>
    )
  }
  return false;
}

function refreshButton( accounts: any, updateNav: Function ) {
  if ( accounts.loading ) {
    return <div data-testid="nav-loading" className="defaultbtn nav__link__button inline-block"><span className="spinner spinner--mini" /></div>;
  }
  return (
    <button type="button" data-testid="nav-refresh" className="defaultbtn nav__link__button inline-block" onClick={() => { updateNav(); }}>
      <div className="nav__link">
        <i className="fas fa-sync-alt" />
      </div>
    </button>
  );
}

function createLinks( links: NavLinks, path: String ) {
  if ( links.error ) {
    return <div className="nav__error">{ links.error }</div>
  }
  return links.data.map( ( link: NavLink, i: Number ) => (
    <Link key={`acclink-${ i }`} to={link.path} className={`nav__account ${ path === link.path ? "nav__account--active" : "" }`}>{link.name}</Link>
  ) );
}

Nav.propTypes = {
  // accounts: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  updateNav: PropTypes.func.isRequired,
  user: PropTypes.object,
};

// eslint-disable-next-line react/jsx-props-no-spreading
export default withRouter( ( props : any  ) => <Nav {...props} /> );
