import React, { useEffect, useState } from "react";
import { RVTable, Form, Field } from "roar-component-library";
import { CSVLink } from "react-csv";
import {
  Map, Marker, Popup, TileLayer,
} from "react-leaflet";
import Sidebar from "../../components/sidebar/Sidebar.tsx";
import LocCast from "../../models/locationsCsvCast";
import Merge from "../../components/Merge";

import EditableMap from "../../components/EditableMap";

function MapView( props ) {
  console.log( props.locations );
  const [ showTable, setshowTable ] = useState( 0 );
  const [ openMergeSidebar, setopenMergeSidebar ] = useState( false );
  const [ mergeData, setmergeData ] = useState( { keywordString: null, replace: null } );

  useEffect( () => {
    setshowTable( `${ Date.now() }-${ props.locations.length }` );
  }, [ props.locations ] );
  const downloadData = LocCast.transform( props.locations );
  const closeSidebar = () => setopenMergeSidebar( false );
  const openSidebar = () => setopenMergeSidebar( true );
  return (
    <div className="flex-wrapper">
      <div className="mr--20">
        <EditableMap {...props} />
        <button type="button" className="primary" onClick={props.handleDraw}>Search</button>
      </div>
      <div className="flex-wrapper__fill" key={showTable}>
        <RVTable
          passedData={downloadData}
          config={{
            columns: LocCast.headers.map( ( header ) => ( {
              displayName: header.label,
              fieldName: header.label,
              sorting: true,
            } ) ),
            debugmode: true,
            selectionBox: false,
            virtualization: {
              tableHeight: 500,
              rowHeight: 40,
            },
          }}
        />
        <div>
          <CSVLink className="primary" filename="location_export.csv" data={downloadData}>Download table</CSVLink>
          <button className="primary" onClick={openSidebar}>Merge Keywords</button>
        </div>
      </div>
      <Sidebar open={openMergeSidebar} back={closeSidebar}>
        <h2>
          Merge keywords
          <button
            type="button"
            onClick={closeSidebar}
            aria-label="Back"
            title="Back"
            className="account__edit"
          >
            <i className="fas fa-arrow-left" />
          </button>
        </h2>
        <Form onFormSubmit={( formData ) => { setmergeData( formData ); }} actionName="Merge" actionClass="primary">
          <Field
            name="keywordString"
            type="textarea"
            parentClass="block"
            default=""
            label="Keywords (Comma-separated)"
            minLen={1}
            trim
          />
          <Field
            name="replace"
            type="text"
            parentClass="block"
            default=""
            label="Text to replace"
            minLen={1}
            trim
          />
        </Form>
        {mergeData.keywordString && openMergeSidebar ? (
          <Merge key={`${ mergeData.keywordString }+${ downloadData.length }`} locations={downloadData} keywordString={mergeData.keywordString} replace={mergeData.replace} />
        ) : false }
      </Sidebar>
    </div>
  );
}

export default MapView;
